<template>
  <div class="add-account-number">
    <van-cell-group class="van-cell-group">
      <van-field  input-align="right" v-model="accountNumber" label="户号" placeholder="请输入您的户号"  clearable right-icon="question-o" v-on:clickRightIcon="$router.push('/accountNumberDescription')"/>
    </van-cell-group>

    <van-cell-group class="van-cell-group">
      <van-field  input-align="right" v-model="typeName" label="类型" placeholder="请选择您的类型" readonly right-icon="question-o" @click="show=true"/>
    </van-cell-group>

  <van-cell-group @click="citySelect">
		<van-field v-model="city" label="城市" placeholder="请选择城市" right-icon="arrow" readonly input-align="right" />
	</van-cell-group>

  <!--  <van-cell-group class="van-cell-group">
      <van-field  input-align="right" v-model="city" label="城市" placeholder="请输入所在城市(例:北京市)"  clearable right-icon="location-o" v-on:clickRightIcon="aearFn(1)" />
    </van-cell-group> -->

    <van-cell-group class="van-cell-group" v-show="type==1">
      <van-field  input-align="right" v-model="idCardNo" maxlength="6" label="身份证后六位" placeholder="请输入您身份证后六位"  clearable right-icon="question-o"/>
    </van-cell-group>

    <van-cell-group class="van-cell-group">
      <van-field  input-align="right" v-model="remark" label="备注" maxlength="8" placeholder="请输入您的备注(非必填)"  clearable right-icon="question-o" />
    </van-cell-group>

    <van-dropdown-menu>
      <van-dropdown-item v-model="type" :options="option" teleport="body" />
    </van-dropdown-menu>
  </div>

  <div class="add-account-number-btn" @click="addAccountNumber">添加</div>

  <!-- 弹出层开始 -->
  <van-popup v-model:show="cityShow" position="bottom" :style="{ height: '50%' }">
  	<van-area title="选择地区" :area-list="areaList" @confirm="cityConfirm" @cancel="cityCancel" />
  </van-popup>
  <van-popup v-model:show="show" position="bottom">
    <van-picker
      title="标题"
      :columns="columns"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
  </van-popup>

</template>

<script>
  import { areaList } from '@vant/area-data';
  import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  name: 'BindAlipay',
  data(){
    return{
      city:'',
      accountNumber:'',
      type: 0,
      show:false,
      cityShow: false, //控制城市选择器显示/隐藏
      areaList, //vant里面提供的通用地区码
      typeName:"",
      idCardNo:"",
      remark:"",
      columns:['住宅', '企事业', '店铺'],
      option: [
        { text: '国家电网', value: 0 },
        { text: '南方电网', value: 1 },
        { text: '中国燃气', value: 2 },
        { text: '华润燃气', value: 3 },
        { text: '港华燃气', value: 4 }
      ]
    }
  },
  beforeCreate () {
  },
  beforeDestroy () {
  },
  
  methods:{
    addAccountNumber(){

      if(this.accountNumber.length==0){
          this.$toast.fail('户号不能为空!');
          return;
      }
      if(this.city.length==0){
          this.$toast.fail('区域不能为空!');
          return;
      }
      if(this.typeName.length==0){
          return this.$toast.fail('类型不能为空!');
      }
      if(this.type==1 && this.idCardNo.length==0){
           return this.$toast.fail('南方电网身份证后六位必填!');
      }

 this.$dialog.confirm({
        title: '添加确认',
        message:
        `当前要添加的户号为 “${this.accountNumber}” ,为了避免失败,请仔细核对,户号错误导致充值失败无法退款,确定给这个户号添加吗?`,
      })
        .then(() => {
          this.axios.post('/app/accountnumber/add', {
                city:this.city,
                accountNumber:this.accountNumber,
                type:this.type,
                typeName:this.typeName,
                identityLast:this.idCardNo,
                remark:this.remark,
              }).then((response) => {
                console.log(response)
                if(response!=500)
                this.$router.back();
              }).catch(function (error) {
                  console.log(error);
              });
        })
        .catch(() => {
          // on cancel
        });

    },
      aearFn(i){
        this.show = true;
        console.log("获取定位");
        AMapLoader.load({
            "key": "2d4144b5959c0681cdcd635488dae29e",              // 申请好的Web端开发者Key，首次调用 load 时必填
            "version": "1.4.15",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            "AMapUI": {             // 是否加载 AMapUI，缺省不加载
                "version": '1.1',   // AMapUI 缺省 1.1
                "plugins":[],       // 需要加载的 AMapUI ui插件
            }
        }).then((AMap)=>{
            AMap.plugin('AMap.Geolocation', ()=>{
              let geolocation = new AMap.Geolocation({
                // 是否使用高精度定位，默认：true
                enableHighAccuracy: true,
                // 设置定位超时时间，默认：无穷大
                timeout: 10000,
                // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                buttonOffset: new AMap.Pixel(10, 20)
              })

              geolocation.getCurrentPosition((status,result)=>{
                this.show = false;
                    if(status=='complete'){
                        // result是具体的定位信息
                        this.city = result.addressComponent.city;

                    }else{
                        // 定位出错
                        this.$toast.fail('定位失败请自行输入地区');
                        console.log("定位失败",result);
                    }
              });
            })
        }).catch(e => {
            console.log(e);
        })
      },onConfirm(typeName){
        this.show=false;
        this.typeName=typeName;
      },onCancel(){
        this.show=false;
      },
			citySelect() {
				this.cityShow = true;
			},
			cityConfirm(parameter) {
        console.log(parameter[1].name)
				this.city = parameter[1].name;
				this.cityShow = false;
			},
			cityCancel() {
				this.cityShow = false;
			}
}
}
</script>

<style scoped>
  .add-account-number{
    padding: 15px 10px;
  }
  .add-account-number img{
    width: 100%;
  }
  .add-account-number .van-cell-group{
    margin: 10px 0;
  }
  .add-account-number-btn{
    width: 95%;
    height: 45px;
    line-height: 45px;
    margin: 15px auto 0;
    text-align: center;
    color: #FFFFFF;
    background: #00ef83;
    border-radius: 5px;
  }
</style>
